'use client';

import { navbarBgColorAtom, navbarOverlayAtom } from '@/store/globalStore';
import { useAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { useEffect } from 'react';

export default function SetNavbarTextColor({
  color,
  overlay,
}: {
  color: string;
  overlay?: string;
}) {
  // @ts-ignore
  useHydrateAtoms([
    [navbarBgColorAtom, color],
    [navbarOverlayAtom, overlay],
  ]);
  const [, setNavbarBgColor] = useAtom(navbarBgColorAtom);
  const [, setNavbarOverlay] = useAtom(navbarOverlayAtom);

  useEffect(() => {
    setNavbarBgColor(color);
  }, [setNavbarBgColor, color]);

  useEffect(() => {
    if (overlay) {
      setNavbarOverlay(overlay);
    } else {
      setNavbarOverlay('overlay');
    }
  }, [setNavbarOverlay, overlay]);

  return <></>;
}
